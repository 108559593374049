// import { RegisterComponent } from 'components/Auth';
import { Routes, Route } from "react-router-dom";
import React from "react";
import AuthLayout from "./AuthLayout";
import Step1 from "./Register/Step1";
import Step2 from "./Register/Step2";
import Step3 from "./Register/Step3";
import Step4 from "./Register/Step4";

const Register = () => {
  return (
    <AuthLayout>
      <Routes>
        <Route path="/" element={<Step1 />} />
        <Route path="step-2" element={<Step2 />} />
        <Route path="step-3" element={<Step3 />} />
        <Route path="verify" element={<Step4 />} />
      </Routes>
    </AuthLayout>
  );
};

export default Register;
