import React from "react";
import { motion } from "framer-motion";
import loginImg from "../../assets/images/login.svg";
import styled from "styled-components";

function AuthLayout({ children }) {
  return (
    <Container>
      <motion.div className="left-pane">
        <img src={loginImg} alt="" />
      </motion.div>
      <motion.div
        initial={{ x: "100vw", opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ type: "tween", duration: 0.5 }}
        className="right-pane"
      >
        {children}
      </motion.div>
    </Container>
  );
}

export default AuthLayout;

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 100vh;
  max-height: 1024px;
  overflow-y: hidden;

  .left-pane {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #28d1ff;

    @media only screen and (max-width: 450px) {
      display: none;
    }

    img {
      width: 70%;
    }
  }
  .right-pane {
    flex: 1;
    background-color: #fff;
    @media only screen and (max-width: 450px) {
      padding: 20px 40px;
    }

    .head {
      text-align: center;
      margin-top: 180px;
      @media only screen and (max-width: 450px) {
        margin-top: 80px;
        text-align: left;
      }

      h3 {
        font-size: 40px;
        color: #28d1ff;
        @media only screen and (max-width: 450px) {
          font-size: 20px;
        }
      }
    }
  }
`;
